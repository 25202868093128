<template>
  <v-sheet
    elevation="2"
    class="white mt-6 mx-4 px-4"
    rounded
  >
    <v-form
      ref="form"
    >
      <v-row>
        <v-col offset-sm="2" cols="12" sm="3">
          <label for="shipper" class="font-weight-bold">{{$_strings.manualOrders.SHIPPER}}</label>
        </v-col>
        <v-col cols="12" sm="4" class="pt-0 pt-sm-2">
          <common-auto-complete-items
            id="shipper"
            type="masterShipper"
            searchName="companyName"
            item-value="id"
            item-text="companyName"
            v-model="form.shipperId"
            dense
            outlined
            class="caption"
            clearable
            :placeholder="`${$_strings.manualOrders.SHIPPER}`"
            :filter="shipperFilter"
            :items="itemsShipper"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.manualOrders.SHIPPER)]"
            @updateItems="updateListShipper"
          />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="4">
          <v-btn
            color="primary"
            @click="submit"
          >
            Berikutnya
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>

export default {
  data() {
    return {
      form: {
        shipperId: null,
        mouId: null,
      },
      itemsShipper: [],
      shipperFilter: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    updateListShipper(items) {
      this.itemsShipper = [...this.itemsShipper, ...items];
    },
    submit() {
      this.form.mouId = this.itemsShipper.find((x) => x.id === +this.form.shipperId)?.mouId;
      const valid = this.$refs.form.validate();
      if (valid) {
        if (!this.form.mouId) return this.$dialog.notify.error('Mou tidak ditemukan');
        this.$router.push({
          name: 'create-manual-order',
          query: {
            mouId: this.form.mouId,
            shipperId: this.form.shipperId,
          },
        });
      }
    },
  },
};
</script>
